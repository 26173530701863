import React, { useMemo } from 'react';
import cn from 'classnames';
import _toNumber from 'lodash/toNumber';

export default function GridRow({
  gap = 0,
  templateColumns = '',
  templateRows = 'auto',
  className = '',
  styles = {},
  children = null,
  ...divElementProps
}) {
  const columnRatio = useMemo(() => {
    if (templateColumns) return templateColumns;
    const totalChildren = children instanceof Array ? children.length : 1;
    return Array(totalChildren)
      .fill(1)
      .map(fragment => `${fragment}fr`)
      .join(' ');
  }, [templateColumns, children]);

  return (
    <div
      {...divElementProps}
      className={cn('uiKit__element uiKit__gridRow', className)}
      style={{
        display: 'grid',
        gridTemplateRows: templateRows,
        gridTemplateColumns: columnRatio,
        gap: _toNumber(gap) ? `${gap}px` : gap,
        ...styles
      }}
    >
      {children}
    </div>
  );
}
