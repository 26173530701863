import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

import { UiKit } from 'components';
import SelectProduct from '../SelectProduct';
import RuleConfiguration from '../../RuleConfiguration';
import { useRecommendationConfig } from '../../../hooks';
import { createRuleConfig } from '../../../methods';

export default function RuleConfig({
  product = null,
  isProductConfirmed = false,
  setProduct = () => {},
  setProductConfirmed = () => {}
}) {
  const {
    customRuleData,
    customRuleOptions,
    updateCustomRuleData
  } = useRecommendationConfig();

  const configData = _get(customRuleData, 'config', {});
  const rule = _get(customRuleData, 'ruleOption', null);
  const configElements = _get(rule, 'config', []);
  const isProductRequired = _get(rule, 'is_product_required', false);
  const isRuleSelectDisabled = _isEmpty(customRuleOptions);

  const onRuleChange = rule => {
    const ruleConfig = _get(rule, 'config', []);
    const config = createRuleConfig(ruleConfig);
    updateCustomRuleData({ ruleOption: rule, config });
  };

  const onRuleConfigChange = (id, value) => {
    updateCustomRuleData({ config: { ...configData, [id]: value } });
  };

  return (
    <div className={styles.container}>
      <p className={styles.description}>
        Select the recommendation rule that will apply on the product space.
      </p>
      <div className={styles.rule}>
        <h4>Recommendation Rule</h4>
        <UiKit.Select
          value={rule}
          options={customRuleOptions}
          onChange={onRuleChange}
          isDisabled={isRuleSelectDisabled}
          label="Select recommendation rule"
          wrapperClassName={styles.dropdown}
        />
        <SelectProduct
          product={product}
          setProduct={setProduct}
          show={isProductRequired}
          isProductConfirmed={isProductConfirmed}
          setProductConfirmed={setProductConfirmed}
        />
        <RuleConfiguration
          configData={configData}
          configElements={configElements}
          onChange={onRuleConfigChange}
        />
      </div>
    </div>
  );
}
