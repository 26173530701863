import React, {
  useContext,
  useState,
  useRef,
  useMemo,
  useCallback
} from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { convertOrderHash } from 'utils/formatters';
import { orderServiceInprogressStatuses } from 'utils/DICTIONARY';
import { RETEX_STATUS } from 'dictionary';
import { StickyButtons, CancelHoldRequest, ProtectedLink } from 'components';
import { OverflowMenu } from 'components/Tables/CustomTable';
import { RetexAction, RequestAction } from 'views/Requests/components';
import { RetexContext } from 'components/RetexDetails';
import { ROUTES as WH_ROUTES } from 'views/Warehouse/stubs';
import { ROUTES as STORE_ROUTES } from 'views/StoreReturns/stubs';

const ROUTES = {
  STORE: STORE_ROUTES.ITEM_SERVICE.ROOT,
  WAREHOUSE: WH_ROUTES.ITEM_INSPECTION.ROOT
};

export default function Actions({ className = '' }) {
  const {
    retex,
    orderService,
    showAction,
    isDisabled,
    isAwatitingAtAdmin,
    setIsDisabled,
    onLoadData
  } = useContext(RetexContext);
  const [isUnlockModalOpen, setIsUnlockModalOpen] = useState(false);
  const modalDataRef = useRef(null);
  const IN_PROGESS_STATUSES = useRef(orderServiceInprogressStatuses()).current;

  const osId = _get(orderService, 'id', '');
  const osStatus = _get(orderService, 'status', false);
  const isLocked = _get(orderService, 'on_hold', false);
  const retexId = _get(retex, 'id', '');
  const retexName = _get(retex, 'name', '');
  const retexStatus = _get(retex, 'status', '');
  const disposition = _get(retex, 'disposition', {});
  const dispositionMode = _get(disposition, 'mode', '');
  const dispositionComment = _get(disposition, 'comment.value', '');
  const dispositionCustomComment = _get(disposition, 'custom_comment', '');
  const urlParsedRetexName = convertOrderHash(retexName, true);

  const isRestocking = dispositionMode === 'resale';
  const isInProgress = IN_PROGESS_STATUSES.includes(osStatus);
  const isAtStore = retexStatus === RETEX_STATUS.AWAITING_STORE_AUTHORIZATION;
  const isAtWarehouse = retexStatus === RETEX_STATUS.AWAITING_WH_AUTHORIZATION;
  const storeRoute = ROUTES.STORE.replace(':serviceId', retexId);
  const warehouseRoute = ROUTES.WAREHOUSE.replace(':id', urlParsedRetexName);

  const onToggleModal = useCallback((modalData = null) => {
    modalDataRef.current = modalData;
    setIsUnlockModalOpen(!!modalData);
  }, []);

  const onBeforeUnlocking = useCallback(() => {
    if (isLocked) {
      onToggleModal({
        type: 'hold',
        orderServiceID: osId,
        extra: { holdVal: false }
      });
    }
  }, [osId, isLocked, onToggleModal]);

  const onAfterUnlocking = useCallback(() => {
    onLoadData();
    onToggleModal();
  }, [onLoadData, onToggleModal]);

  const onBeforeProcessing = useCallback(() => setIsDisabled(true), [
    setIsDisabled
  ]);

  const onAfterProcessing = useCallback(() => {
    setIsDisabled(false);
    onLoadData();
  }, [setIsDisabled, onLoadData]);

  const overflowMenuItems = useMemo(() => {
    if (isLocked || !isInProgress) return [];
    return [
      {
        title: 'Lock Request',
        onClick: () => {
          onToggleModal({
            type: 'hold',
            orderServiceID: osId,
            extra: { holdVal: true }
          });
        }
      }
    ];
  }, [isLocked, isInProgress, osId, onToggleModal]);

  const retexActionProps = {
    data: retex,
    isDisabled,
    isRestocking,
    comment: dispositionComment,
    customComment: dispositionCustomComment,
    onBeforeProcessing,
    onAfterProcessing
  };

  if (!showAction) return null;

  return (
    <StickyButtons
      containerClassName={cn(styles.container, className)}
      contentClassName={styles.content}
    >
      <If test={!isLocked}>
        <OverflowMenu
          alignToRight
          direction="up"
          items={overflowMenuItems}
          isDisabled={!isInProgress || isDisabled}
        />
      </If>
      <If test={isLocked}>
        <RequestAction
          data={orderService}
          isDisabled={isDisabled}
          onBeforeProcessing={onBeforeUnlocking}
          onAfterProcessing={onAfterUnlocking}
        />
      </If>
      <If test={!isLocked && isAwatitingAtAdmin}>
        <RetexAction isApproving={false} {...retexActionProps} />
        <RetexAction isApproving {...retexActionProps} />
      </If>
      <If test={!isLocked && isAtWarehouse}>
        <ProtectedLink
          _key="warehouse.enabled"
          to={warehouseRoute}
          className={styles.redirectLink}
          tooltipMessage="You don’t have access to the warehouse. Please contact your super admin."
        >
          Approve at Warehouse
        </ProtectedLink>
      </If>
      <If test={!isLocked && isAtStore}>
        <ProtectedLink
          _key="store.enabled"
          to={storeRoute}
          className={styles.redirectLink}
          tooltipMessage="You don’t have access to the store. Please contact your super admin."
        >
          Approve at Store
        </ProtectedLink>
      </If>
      {modalDataRef.current && (
        <CancelHoldRequest
          isOpen={isUnlockModalOpen}
          data={modalDataRef.current}
          toggle={onToggleModal}
          done={onAfterUnlocking}
        />
      )}
    </StickyButtons>
  );
}
