import uuid from 'uuid/v4';
import _get from 'lodash/get';

import createEmptyFormData from './createEmptyFormData';
import createRuleData from './createRuleData';

export default function createConfig({
  recommendation = null,
  products = [],
  rules = {},
  reasons = []
}) {
  const parentRules = _get(rules, 'parentRules', []);
  const childRules = _get(rules, 'childRules', []);

  let defaultRule = _get(rules, 'parentRules[0]', null);
  let formData = createEmptyFormData(defaultRule);

  if (recommendation) {
    const ruleId = _get(recommendation, 'rule_id', '');
    const selectedRule = parentRules.find(({ id }) => ruleId === id);
    const selectedRules = _get(recommendation, 'rules', []);
    const selectedRuleConfig = _get(recommendation, 'config', []);
    const attachedReasons = _get(recommendation, 'attached_reasons', []);
    const isRuleCustom = _get(selectedRule, 'is_custom', false);
    const ruleConfig = _get(selectedRule, 'config', []);

    const reasonsMap = attachedReasons.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});

    const ruleConfigData = createRuleData(selectedRuleConfig, ruleConfig);

    const productsMap = products.reduce((acc, product) => {
      acc[product.objectID] = product;
      return acc;
    }, {});

    const addedRules = !isRuleCustom
      ? []
      : selectedRules.map(ruleInfo => {
          const key = uuid();
          const config = _get(ruleInfo, 'config', {});
          const ruleId = _get(ruleInfo, 'rule_id', '');
          const productId = _get(ruleInfo, 'product_id', '');

          const ruleOption = childRules.find(({ id }) => id === ruleId);
          const ruleConfig = _get(ruleOption, 'config', []);
          const isProductRequired = _get(
            ruleOption,
            'is_product_required',
            false
          );
          const product = !isProductRequired
            ? null
            : _get(productsMap, productId, null);
          const configData = createRuleData(config, ruleConfig);
          const rule = {
            key,
            product,
            ruleOption,
            config: configData
          };
          if ('id' in ruleInfo) {
            rule.id = ruleInfo.id;
          }
          return rule;
        });

    formData = {
      rules: addedRules,
      rule: selectedRule,
      config: ruleConfigData,
      title: _get(recommendation, 'title', ''),
      isEnabled: _get(recommendation, 'is_enabled', false),
      attachedReasons: reasons.filter(({ id }) => id in reasonsMap)
    };
  }

  return {
    parentRules,
    childRules,
    formData,
    reasons
  };
}
