import CONFIGURE_ROUTES from 'views/Configure/stubs/routes';
import SETTNGS_ROUTE from 'views/Settings/stubs/routes';

export const actionIdMap = {
  'rr-low-balance': 'rr-low-balance',
  'rr-no-balance': 'rr-no-balance',
  'no-default-warehouse': 'no-default-warehouse',
  'no-aggregator-setup': 'no-aggregator-setup',
  'refund-processing-fee': 'refund-processing-fee',
  purchases: 'purchases',
  aggregators: 'aggregators',
  'tag-based-rules': 'tag-based-rules'
};

export const doActionTemplateId = (actionId, { redirectFn = () => {} }) => {
  switch (actionId) {
    case actionIdMap['rr-low-balance']:
    case actionIdMap['rr-no-balance']: {
      redirectFn(SETTNGS_ROUTE.MANAGE_SUBSCRIPTION);
      break;
    }
    case actionIdMap['no-default-warehouse']: {
      redirectFn(CONFIGURE_ROUTES.FACILITIES);
      break;
    }
    case actionIdMap['no-aggregator-setup']:
    case actionIdMap.aggregators: {
      redirectFn(CONFIGURE_ROUTES.AGGREGATORS);
      break;
    }
    case actionIdMap['refund-processing-fee']: {
      redirectFn(
        `${CONFIGURE_ROUTES.REFUND_METHODS}/original-payment-method#fee`
      );
      break;
    }
    case actionIdMap['purchases']: {
      redirectFn(CONFIGURE_ROUTES.PURCHASES);
      break;
    }
    case actionIdMap['tag-based-rules']: {
      redirectFn(CONFIGURE_ROUTES.TAG_BASED_RULES);
      break;
    }
    default:
      break;
  }

  return null;
};
