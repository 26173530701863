import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import style from './StickyButtons.module.scss';

import { UiKit } from 'components';
import { areObjectsEqual } from 'utils/methods';

export default function StickyButtons({
  data = null,
  helptext = <></>,
  initialData = null,
  isDataSame = undefined,
  isEditMode = false,
  alwaysEdit = false,
  onConfirm = () => {},
  isProcessing = false,
  isConfirmDisabled = false,
  title = 'Save changes before leaving',
  confirmBtnText = 'Save Changes',
  cancelButtonText = 'Exit without saving',
  confirmBtnClassName = '',
  position = 'fixed', // sticky | fixed
  isCancelDisabled = false,
  containerClassName = '',
  contentClassName = '',
  cancelBtnClassName = '',
  routeFragementToDiscardOnCancel = '/edit',
  children = null
}) {
  const [showModal, setShowModal] = useState(false);
  const goingToThisPath = useRef(null);

  const { pathname } = useLocation();
  const { push, block } = useHistory();

  const editCheck = alwaysEdit || isEditMode;

  useEffect(() => {
    block(({ pathname: path, hash }) => {
      goingToThisPath.current = null;
      let checkDataEquality;
      let mainEqualityCheck;
      let route = path;
      if (hash) route += hash;

      if (initialData && data) {
        checkDataEquality = areObjectsEqual(initialData, data);
      }
      if (checkDataEquality !== undefined) {
        mainEqualityCheck = !checkDataEquality;
      }
      if (isDataSame !== undefined) {
        mainEqualityCheck = !isDataSame;
      }
      if (editCheck && mainEqualityCheck && path !== window.location.pathname) {
        goingToThisPath.current = route;
        setShowModal(true);
        return false;
      }
      return true;
    });

    return () => block(() => true);
  }, [editCheck, initialData, data, isDataSame, block]);

  const closeModal = useCallback(() => setShowModal(false), []);

  const handleRedirect = useCallback(() => {
    block(() => true);
    if (goingToThisPath.current) {
      push(goingToThisPath.current);
      closeModal();
    }
  }, [block, push, closeModal]);

  const onCancelEdit = useCallback(() => {
    if (isCancelDisabled) return;
    const path = pathname.replace(routeFragementToDiscardOnCancel, '');
    push(path);
  }, [isCancelDisabled, pathname, push, routeFragementToDiscardOnCancel]);

  const onConfirmClose = useCallback(
    event => {
      onConfirm(event);
      if (alwaysEdit) closeModal();
    },
    [onConfirm, closeModal, alwaysEdit]
  );

  const mainStyle = useMemo(() => {
    const isFixed = position === 'fixed';
    const margin = isFixed ? '20px 0 0' : '20px -24px 0 -24px';
    return { position, margin };
  }, [position]);

  return (
    <>
      <div
        style={mainStyle}
        className={cn(style.wrapper, containerClassName)}
        data-type="footer"
      >
        <div className={style.helptext}>{helptext}</div>
        <div className={style.actions}>
          {isEditMode && (
            <UiKit.Button
              customType="inline"
              onClick={onCancelEdit}
              disabled={isCancelDisabled}
              className={cancelBtnClassName}
            >
              Cancel
            </UiKit.Button>
          )}
          <div className={cn(style.save, contentClassName)}>{children}</div>
        </div>
      </div>
      <UiKit.Modal
        title={title}
        isOpen={showModal}
        confirmBtnText={confirmBtnText}
        onConfirm={onConfirmClose}
        onClose={closeModal}
        isCancelBtnHidden
        isProcessing={isProcessing}
        isCustomBtnVisible
        customBtnText={cancelButtonText}
        onCustomBtnClick={handleRedirect}
        confirmBtnClassName={confirmBtnClassName}
        isConfirmDisabled={isConfirmDisabled}
        footerClassName={style.footer}
      >
        You have unsaved changes in page. If you exit this page, these changes
        will be lost.
      </UiKit.Modal>
    </>
  );
}
