import React from 'react';
import cn from 'classnames';
import _get from 'lodash/get';
import styles from './index.module.scss';

import { If } from 'utils';
import { PLAN_MODE, PLAN_SLUGS } from 'dictionary';

export default function Plan({
  data = {},
  processingPlan = null,
  term = PLAN_MODE.MONTHLY,
  isNotifiedForEnterprise = false,
  onSubscribe = () => {}
}) {
  const isAnnualPlan = term === PLAN_MODE.YEARLY;
  const slug = _get(data, 'slug', '');
  const name = _get(data, 'name', '');
  const description = _get(data, 'description', '');
  const maxRequests = _get(data, 'usage_info.max_units', '');
  const planPrice = _get(data, `prices.${term}.price`);
  const rate = isAnnualPlan ? planPrice / 12 : planPrice;

  const isProcessing = !!processingPlan;
  const isSubscribing = slug === processingPlan;
  const isEnterprisePlan = slug === PLAN_SLUGS.ENTERPRISE;

  const showButton = !isEnterprisePlan || !isNotifiedForEnterprise;
  const buttonText = isEnterprisePlan ? 'Contact Us' : "Let's do this!";
  const buttonProcessingText = isEnterprisePlan
    ? 'Notifying...'
    : 'Subscribing...';
  const buttonClassName = cn(styles.subscribeBtn, {
    [styles.processing]: isSubscribing
  });

  const title = isEnterprisePlan ? (
    <h5>Contact Us</h5>
  ) : (
    <>
      <span className={styles.currency}>$</span>
      <h5>{rate}</h5>
      <span>/month</span>
    </>
  );

  const planClassName = cn(styles.plan, { [styles.preferred]: isAnnualPlan });

  return (
    <div className={planClassName}>
      <div className={styles.planName}>{name}</div>
      <div className={styles.planContent}>
        <div className={styles.planInfo}>
          <div className={styles.infoRow}>{title}</div>
          <span className={styles.decription}>{description}</span>
          <If test={maxRequests}>
            <strong>
              {isEnterprisePlan ? 'Above' : 'Upto'} {maxRequests} monthly
              returns
            </strong>
          </If>
        </div>
        <If test={!showButton}>
          <div className={styles.notifiedMsg}>
            Our team will get back to you shortly. Choose another plan in the
            meantime.
          </div>
        </If>
        <If test={showButton}>
          <button
            onClick={() => onSubscribe(slug, term)}
            className={buttonClassName}
            disabled={isProcessing}
          >
            {isSubscribing ? buttonProcessingText : buttonText}
          </button>
        </If>
      </div>
    </div>
  );
}
