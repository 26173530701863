import React, { useCallback, useMemo, useState } from 'react';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import _toLower from 'lodash/toLower';
import _isObject from 'lodash/isObject';
import styles from './index.module.scss';

import { If } from 'utils';
import {
  useDebounce,
  useEcomPlatform,
  useStoreCurrency,
  useSupportedProductAttributes
} from 'utils/hooks';
import { PriceInput, StickyButtons, Toggle, UiKit } from 'components';
import { createNestedCheckboxSchema } from 'components/UiKit/NestedCheckboxes/util';
import { EditFooter } from 'components/EditModal';
import { TableSearch } from 'components/Tables';
import MultiSelect from '../MultiSelect';
import { getCopies } from '../../../methods';
import { useProductsCatalog } from '../../../hooks';

export default function Form({
  dirty,
  errors,
  values,
  isSubmitting,
  initialValues,
  resetForm,
  handleSubmit,
  setFieldValue,
  setFieldTouched
}) {
  const storeCurrency = useStoreCurrency();
  const { ecommPlatformName } = useEcomPlatform();
  const { entity, categoriesOptions } = useProductsCatalog();
  const {
    supportsType,
    supportsCategory,
    supportsCollection
  } = useSupportedProductAttributes();

  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue);

  const searchQuery = _trim(searchValue ? debouncedSearchValue : searchValue);
  const copies = getCopies(
    entity,
    ecommPlatformName,
    supportsType,
    supportsCategory
  );

  const productCatagoryOptions = useMemo(() => {
    return createNestedCheckboxSchema(categoriesOptions, {
      skipOption: category => {
        const query = _get(category, 'query', '');
        return !query.includes(_toLower(searchQuery));
      }
    });
  }, [categoriesOptions, searchQuery]);

  const onSelectAllCategories = useMemo(() => {
    return _isObject(values.product_categories)
      ? Object.values(values.product_categories).every(Boolean)
      : false;
  }, [values]);

  const handleProductTypesChange = data => {
    setFieldValue('product_types', data);
    setFieldTouched('product_types');
  };

  const onToggleProductCategory = useCallback(
    categoryId => {
      const currentCategoriesMap = _get(values, 'product_categories', {});
      setFieldTouched('product_categories');
      setFieldValue('product_categories', {
        ...currentCategoriesMap,
        [categoryId]: !_get(currentCategoriesMap, categoryId, false)
      });
    },
    [values, setFieldValue, setFieldTouched]
  );

  const handleCollectionsChange = data => {
    setFieldValue('collections', data);
    setFieldTouched('collections');
  };

  const handleExcludedTagsChange = data => {
    const trimmedSpacesData = (data || [])
      .map(data => {
        return {
          value: data.value.trim(),
          label: data.label.trim()
        };
      })
      .filter(data => !!data.value); // Removes empty strings
    setFieldValue('excluded_tags', trimmedSpacesData);

    setFieldTouched('excluded_tags');
  };

  const handleMinPriceChange = value => {
    setFieldValue('min_price', value);
    setFieldTouched('min_price');
  };

  const handleDigitalProductsChange = data => {
    setFieldValue('show_digital_products', !data.isActive);
    setFieldTouched('show_digital_products');
  };

  const selectAllToggle = useCallback(
    toggleFlag => {
      const currentCategoriesMap = _get(values, 'product_categories', {});
      const newMap = Object.keys(currentCategoriesMap).reduce((acc, key) => {
        acc[key] = toggleFlag;
        return acc;
      }, {});
      setFieldValue('product_categories', newMap);
    },
    [values, setFieldValue]
  );

  return (
    <>
      <UiKit.GridRow templateColumns="1fr" gap="56px">
        <UiKit.GridRow templateColumns="1fr 1fr" gap="56px 32px">
          <If test={supportsType}>
            <div className={styles.group}>
              <label className={styles.label}>
                {copies.productTypes.label}
              </label>
              <p className={styles.description}>
                {copies.productTypes.description}
              </p>
              <MultiSelect
                options={values.product_types}
                onChange={handleProductTypesChange}
              />
            </div>
          </If>
          <If test={supportsCategory}>
            <div className={styles.group}>
              <label className={styles.label}>
                {copies.productCategories.label}
              </label>
              <p className={styles.description}>
                {copies.productCategories.description}
              </p>
              <TableSearch
                value={searchValue}
                onChange={setSearchValue}
                onCancel={() => setSearchValue('')}
                placeholder="Search a product category"
                className={styles.search}
                searchIconClassName={styles.searchIcon}
                containerClassName={styles.searchWrapper}
              />
              <div className={styles.selectAll}>
                <UiKit.Checkbox
                  title="Select All"
                  checked={onSelectAllCategories}
                  toggle={selectAllToggle}
                />
              </div>
              <div className={styles.nestedSelect}>
                <UiKit.NestedCheckboxes
                  options={productCatagoryOptions}
                  valuesMap={values.product_categories}
                  onToggle={onToggleProductCategory}
                />
              </div>
            </div>
          </If>
          <If test={supportsCollection}>
            <div className={styles.group}>
              <label className={styles.label}>{copies.collections.label}</label>
              <p className={styles.description}>
                {copies.collections.description}
              </p>
              <MultiSelect
                options={values.collections}
                onChange={handleCollectionsChange}
              />
            </div>
          </If>
        </UiKit.GridRow>
        <UiKit.GridRow templateColumns="1fr 1fr" gap="56px 32px">
          <div className={styles.group}>
            <label className={styles.label}>{copies.tags.label}</label>
            <p className={styles.description}>{copies.tags.description}</p>
            <UiKit.Select
              isMulti
              isCreatable
              isClearable={false}
              disableSelectAllOption
              value={values.excluded_tags}
              options={values.excluded_tags}
              onChange={handleExcludedTagsChange}
              placeholder="Enter the product tags here..."
            />
          </div>
          <div className={styles.group}>
            <label className={styles.label}>{copies.price.label}</label>
            <p className={styles.description}>{copies.price.description}</p>
            <PriceInput
              currency={storeCurrency}
              value={values.min_price}
              onChange={handleMinPriceChange}
            />
          </div>
          <div className={styles.group}>
            <UiKit.GridRow
              gap="8"
              templateColumns="auto 1fr"
              className="align-items-center"
            >
              <Toggle
                size="sm"
                isActive={values.show_digital_products}
                onClick={handleDigitalProductsChange}
              />
              <label className={styles.label}>
                {copies.digitalProducts.label}
              </label>
            </UiKit.GridRow>
            <p className={styles.description}>
              {copies.digitalProducts.description}
            </p>
          </div>
        </UiKit.GridRow>
      </UiKit.GridRow>
      <StickyButtons
        alwaysEdit
        initialData={initialValues}
        data={values}
        onConfirm={handleSubmit}
        isProcessing={isSubmitting}
        helptext={
          <UiKit.ErrorMessage
            hide={!errors.submit}
            msg={errors.submit}
            className="font-weight-bold"
          />
        }
      >
        <EditFooter
          className="flex-nowrap"
          toggle={() => resetForm()}
          cancelText={'Reset'}
          actionText={'Save Changes'}
          actionType="submit"
          processing={isSubmitting}
          disabledSubmit={!dirty}
          disabledCancel={!dirty}
          confirm={handleSubmit}
          customClassName={'mx-auto'}
          removeParent
        />
      </StickyButtons>
    </>
  );
}
