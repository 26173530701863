import _get from 'lodash/get';

import createRuleConfig from './createRuleConfig';

export default function createEmptyFormData(defaultRule = null) {
  const ruleConfig = _get(defaultRule, 'config', []);
  const config = createRuleConfig(ruleConfig);

  return {
    title: '',
    rule: defaultRule,
    rules: [],
    attachedReasons: [],
    isEnabled: false,
    config
  };
}
